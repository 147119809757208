<template>
    <div>
        <div class="merchant-header">
            <div class="container">
                <div class="d-flex mb-3">
                    <slot name="backbtn"></slot>
                    <p class="merchant-header-title ml-2">Select Merchant</p>
                </div>
                <div class="search-merchant-input">
                    <svg width="42" viewBox="0 0 42 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M41.303 38.0504L33.7652 30.5642C39.6509 23.0264 39.0313 12.2876 32.268 5.52428C28.7056 1.96189 23.9557 0 18.8961 0C13.8365 0 9.08666 1.96189 5.52428 5.52428C1.96189 9.08666 0 13.8365 0 18.8961C0 23.9557 1.96189 28.7056 5.52428 32.268C9.08666 35.8304 13.8365 37.7923 18.8961 37.7923C23.0781 37.7923 27.2084 36.3983 30.5126 33.7652L37.9988 41.3546C38.4634 41.8193 39.0313 42.0258 39.6509 42.0258C40.2704 42.0258 40.8384 41.7677 41.303 41.3546C42.2323 40.4769 42.2323 38.9797 41.303 38.0504ZM33.1457 18.8961C33.1457 22.7167 31.6484 26.279 28.9637 28.9637C26.279 31.6484 22.665 33.1457 18.8961 33.1457C15.1272 33.1457 11.5132 31.6484 8.82852 28.9637C6.14382 26.279 4.64659 22.665 4.64659 18.8961C4.64659 15.0756 6.14382 11.5132 8.82852 8.82852C11.5132 6.14382 15.1272 4.64659 18.8961 4.64659C22.7167 4.64659 26.279 6.14382 28.9637 8.82852C31.6484 11.5132 33.1457 15.0756 33.1457 18.8961Z" fill="black"/>
                    </svg>
                    <input class="form-control" type="text" placeholder="Search" v-model="searchTerm">
                </div>
            </div>
        </div>
        <div class="merchant-body mt-3">
            <div class="container">
                <div class="row mx-0">
                    <div v-for="(merchant, i) in filteredMerchants" :key="i" class="col-6 col-md-3 col-lg-3 mb-3 px-2">
                        <a class="select-merchant-btn" href="javascript:void(0)" @click="selectMerchant(merchant)">
                            <div :class="selectedMerchant.id == merchant.id ? 'merchant-card active' : 'merchant-card'">
                                <img :src="merchant.logo_url" :alt="merchant.merchant_name" v-if="merchant.logo_url!=null && merchant.logo_url!=''">
                                <span v-else>{{ merchant.merchant_name }}</span> 
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        value: Object,
        merchantPoints: Array,
        searchTerm: String
    },
    data(){
        return{
            selectedMerchant: {},
        }
    },
    mounted(){
        this.selectedMerchant = this.value
    },
    methods:{
        selectMerchant(merchant){
            this.selectedMerchant = merchant
            this.$emit('input', merchant)
            this.$parent.redirectBackToDashboard();
        }
    },
    watch: {
        "value": function(){
            if(this.value){
                this.selectedMerchant = this.value
            }else{
                this.selectedMerchant = {}
            }

        }
    },
    computed: {
        filteredMerchants() {
            return this.merchantPoints.filter((merchant) => {
                return merchant.merchant_name ? merchant.merchant_name.toLowerCase().includes(this.searchTerm.toLowerCase()) : null;
            });
        }
    }
}

</script>

<style>
.merchant-header{
    background: #fff;
    padding: 50px 0 18px 0;
    border-radius: 0 0 20px 20px;
}
.merchant-header-title{
    margin: 0;
    font-size: 18px;
    font-weight: 700;
}

.search-merchant-input{
    background-color: #ECECEC;
    padding: 5px 15px;
    display: flex;
    border-radius: 6px;
}
.search-merchant-input svg{
    width: 17px;
}
.search-merchant-input input{
    border: 0;
    width: 100%;
    background-color: #ECECEC;
}
.search-merchant-input input:active, .search-merchant-input input:focus, .search-merchant-input input:focus-visible{
    border: 0;
    box-shadow: none;
    background-color: #ECECEC;
}
.search-merchant-input input::placeholder{
    color: black;
    font-weight: 500;
}
.search-merchant-input input:-ms-input-placeholder { /* Internet Explorer 10-11 */
 color: black;
 font-weight: 500;
}

.search-merchant-input input::-ms-input-placeholder { /* Microsoft Edge */
 color: black;
 font-weight: 500;
}

.merchant-body{
    height: calc(100vh - 175px);
    overflow-y: scroll;
}
.merchant-card{
    background-color: #fff;
    padding: 30px 30px;
    border-radius: 10px;
}
.merchant-card.active{
    border: 2px solid;
}
.merchant-card img{
    width: 100%;
}
.select-merchant-btn, .select-merchant-btn:hover{
    color: black;
    text-decoration: none;
}
</style>